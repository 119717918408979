import 'intersection-observer';
import svg4everybody from 'svg4everybody';
import picturefill from 'picturefill';
import App from '../../common/app/app';


class OaoApp extends App {

	injectCurrentLinkManager(currentLinkManager) {
		this.currentLinkManager = currentLinkManager;
	}


	injectNavigationTracker(tracker) {
		this.tracker = tracker;
	}


	injectHashScroller(hashScroller) {
		this.hashScroller = hashScroller;
	}


	injectPageSlots(pageSlots) {
		this.pageSlots = pageSlots;
	}


	injectPages(pages) {
		this.pages = pages;
	}


	init() {
		svg4everybody();
		picturefill();
		return super.init();
	}

}

export default OaoApp;
