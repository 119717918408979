import domMixin from '../dom/dom-mixin';
import componentsMixin from '../component/components-mixin';
import contextsMixin from '../context/contexts-mixin';
import navigationMixin from '../navigation/navigation-mixin';


class App extends contextsMixin(componentsMixin(navigationMixin(domMixin()))) {
	constructor({root = document.body, requiredStyles = [], requiredFonts = [], requiredIconFiles = []} = {}) {
		super();
		this.root = root;
		this.requiredStyles = requiredStyles;
		this.requiredFonts = requiredFonts;
		this.requiredIconFiles = requiredIconFiles;

		this.fontsLoadedCallbacks = [];
		this.fontsLoadedCount = 0;
	}


	injectFontChecker(fontChecker) {
		this.fontChecker = fontChecker;
	}


	injectStyleChecker(styleChecker) {
		this.styleChecker = styleChecker;
	}


	injectIconFilesLoader(iconFilesLoader) {
		this.iconFilesLoader = iconFilesLoader;
	}


	init() {
		let promise = Promise.resolve();

		// waiting the load of required styles
		if (this.styleChecker && this.requiredStyles.length) {
			const stylePromises = this.requiredStyles.map((style) => this.styleChecker.checkStyle(style));
			promise = promise.then(() => Promise.all(stylePromises));
		}

		// init components engine
		promise = promise.then(() => {
			this.components.init(this.root);
		});

		// waiting the load of required fonts
		if (this.fontChecker && this.requiredFonts.length) {
			const fontPromises = this.requiredFonts.map((font) => this.fontChecker.checkFont(font.name, font.options || {}));
			promise = promise
				.then(() => Promise.all(fontPromises))
				.then(() => this.processFontsCallbacks());
		}

		// waiting the load of required icon files
		if (this.iconFilesLoader && this.requiredIconFiles.length) {
			promise = promise.then(() => this.iconFilesLoader.load(this.requiredIconFiles));
		}
		return promise;
	}


	deinit() {}


	execute() {}


	addFontsLoadedCallback(callback) {
		this.fontsLoadedCallbacks.push(callback);
		return this;
	}


	processFontsCallbacks() {
		for (const callback of this.fontsLoadedCallbacks) {
			callback();
		}
		this.fontsLoadedCallbacks = [];
		return Promise.resolve();
	}

}


export default App;
