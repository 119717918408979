import SlideAnimator from './slide-animator';


class SwapSlideAnimator extends SlideAnimator {

	constructor({currentClass = 'current'}) {
		super();
		this.currentClass = currentClass;
	}


    switch(currentSlide, newSlide, direction) {
		const newElement = newSlide.getElement();
		this.classList(currentSlide.getElement()).remove(this.currentClass);
		this.classList(newElement).add(this.currentClass);
		return this.onTransitionEnd(newElement);
	}

	destroy() {}

}


export default SwapSlideAnimator;
