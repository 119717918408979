import Slideshow from './slideshow';
import SlideLoader from './slide-loader';
import FirstBeforeSlideLoader from './first-before-slide-loader';
import NextSlideLoader from './next-slide-loader';
import NextZoomableSlideLoader from './next-zoomable-slide-loader';
import SlideAnimator from './slide-animator';
import SwapSlideAnimator from './swap-slide-animator';
import SlideshowAutoplayer from './slideshow-autoplayer';
import Slide from './slide';
import ImageSlide from './image-slide';
import ZoomableImageSlide from './zoomable-image-slide';


export default (di) => {
	di
		.setType({type: Slideshow, name: 'Slideshow', parent: 'PageComponent', setters: {
				injectLoaderFactory: di.lazyNew('Factory', {suffix: 'SlideLoader'}),
				injectAnimatorFactory: di.lazyNew('Factory', {suffix: 'SlideAnimator'}),
				injectAutoplayerFactory: di.lazyNew('Factory', {suffix: 'SlideshowAutoplayer'})
			}
		})
		.setType({type: SlideLoader, name: 'SlideLoader', mixins: ['domMixin']})
		.setType({type: NextSlideLoader, name: 'NextSlideLoader', parent: 'SlideLoader'})
		.setType({type: NextZoomableSlideLoader, name: 'NextZoomableSlideLoader', parent: 'NextSlideLoader'})
		.setType({type: FirstBeforeSlideLoader, name: 'FirstBeforeSlideLoader', parent: 'SlideLoader'})
		.setType({type: SlideAnimator, name: 'SlideAnimator', mixins: ['domMixin']})
		.setType({type: SwapSlideAnimator, name: 'SwapSlideAnimator', parent: 'SlideAnimator'})
		.setType({type: SlideshowAutoplayer, name: 'SlideshowAutoplayer'})
		.setType({type: Slide, name: 'Slide', parent: 'PageComponent'})
		.setType({type: ImageSlide, name: 'ImageSlide', parent: 'Slide'})
		.setType({type: ZoomableImageSlide, name: 'ZoomableImageSlide', parent: 'ImageSlide'})
	;
};
