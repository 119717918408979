import domMixin from '../dom/dom-mixin';


class SlideLoader extends domMixin() {

    load(slideshow) {
		const slides = slideshow.getSlides();
		if (slides.length) {
			const slidePromises = slides.map((slide) => slide.load());
			return Promise.all(slidePromises).then(() => {
				slideshow.setInteractive();
				return slideshow;
			});
		}
		return Promise.resolve(slideshow);
	}


	destroy() {}

}

export default SlideLoader;
