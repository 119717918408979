import PageComponent from '../component/page-component';


class ExternalVideo extends PageComponent {

	constructor({
		element,
		root,
		linkAttribute = 'externalVideoLink',
		playerAttribute = 'externalVideoPlayer',
		visiblePlayerClass = 'visiblePlayer'
	} = {}) {
		super({element: element, root: root});
		this.defaults.linkAttribute = linkAttribute;
		this.defaults.playerAttribute = playerAttribute;
		this.defaults.visiblePlayerClass = visiblePlayerClass;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		this.link = this.element.querySelector(this.dataSelector(data.linkAttribute));
		this.source = this.element.querySelector(this.dataSelector(data.playerAttribute));
		if (this.link && this.source) {
			this.listeners.click = this.events.on(this.element, this.dataSelector(data.linkAttribute), 'click', this.onClick.bind(this));
			this.playerAttribute = data.playerAttribute;
			this.visiblePlayerClass = data.visiblePlayerClass;
			this.wrapper = this.link.parentNode;
		}
	}


	onClick(event) {
		if (event.button === 0 && !event.ctrlKey && !event.metaKey) {
			event.preventDefault();
			this.listeners.click.destroy();
			delete this.listeners.click;
			this.wrapper.innerHTML = this.source.textContent;
			this.classList(this.element).add(this.visiblePlaerClass);
		}
	}

}


export default ExternalVideo;
