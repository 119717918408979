import OaoApp from './oao-app';


export default (di) => {
	di
		.setType({
			type: OaoApp, name: 'OaoApp', parent: 'App', params: {
				requiredStyles: [],
				requiredFonts: []
			}, setters: {}
		})
		.set('app', di.lazyNew('OaoApp'))
	;
};
