import SlideLoader from './slide-loader';


class NextSlideLoader extends SlideLoader {

    load(slideshow) {
		this.slideshow = slideshow;
		this.slidesCount = slideshow.getSlidesCount();

		if (this.slidesCount) {
			const currentSlide = slideshow.getCurrentSlide();
			if (currentSlide) {
				currentSlide.load().then(() => {
					slideshow.setInteractive();
					slideshow.closeAsyncEvent('interactive');
					this.loadNext(currentSlide);
					return Promise.resolve(slideshow);
				});
			} else {
				slideshow.setInteractive();
				slideshow.closeAsyncEvent('interactive');
			}
			this.selectListener = this.events.on(slideshow.getElement(), this.dataSelector(slideshow.getSlideAttribute()), 'slide:current', this.onSelectSlide.bind(this));
		} else {
			this.selectListener = null;
		}
		return Promise.resolve(slideshow);
	}


	onSelectSlide(event) {
		const slide = event.detail.component;
		this.loadNext(slide);
	}


	getNextSlide(slide) {
		const index = slide.getIndex();
		const nextIndex = (index + 1) % this.slidesCount;
		if (nextIndex !== index) {
			const nextSlide = this.slideshow.getSlide(nextIndex);
			return nextSlide;
		}
		return null;
	}


	loadNext(slide) {
		const nextSlide = this.getNextSlide(slide);
		if (nextSlide) {
			return nextSlide.load();
		}
		return Promise.resolve();
	}


	destroy() {
		super.destroy();
		if (this.selectListener) {
			this.selectListener.destroy();
		}
	}

}

export default NextSlideLoader;
