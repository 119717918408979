import request from 'superagent';
import PageComponent from '../../common/component/page-component';
import resultTemplate from './template/result';

class SearchLayer extends PageComponent {

	constructor({ root, element, resultClass = 'searchRresult', resultsAttr = 'searchLayerResults', urlAttr = 'url', inputAttr = 'searchLayerInput'}) {
		super({root: root, element: element});

		this.resultClass = resultClass;
		this.resultsAttr = resultsAttr;
		this.urlAttr = urlAttr;
		this.inputAttr = inputAttr;
	}

	prepare() {
		this.resultsNode = this.element.querySelector(this.dataSelector(this.resultsAttr));
		this.inputNode = this.element.querySelector(this.dataSelector(this.inputAttr));

		this.url = this.dataAttr().get(this.urlAttr );

		this.listeners.input = this.events.on(this.inputNode, 'input', this.onInput.bind(this));
	}


	start() {
		this.inputNode.focus();
	}


	onInput() {
		if (this.inputNode.value.length === 0) {
			this.classList(document.body).remove(this.resultClass);
		}
		request
			.get(this.url)
			.query({search: this.inputNode.value})
			.set('X-Requested-With', 'XMLHttpRequest')
			.set('Accept', 'application/json')
			.end((error, response) => {
				if (!error && response.ok) {
					let newHtml = '';
					for (const elem of response.body) {
						newHtml += resultTemplate({
							url: elem.url,
							text: elem.title,
							search: this.inputNode.value
						});
					}
					this.classList(document.body).toggle(this.resultClass, newHtml !== '');
					this.resultsNode.innerHTML = newHtml;
				}
			})
		;
	}
}

export default SearchLayer;
