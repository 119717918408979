import {getScrollTop} from 'get-scroll';
// import getOffsetTop from '../../common/utils/offset-top';
import PageComponent from '../../common/component/page-component';

class SearchButton extends PageComponent {

	constructor({root, element, openClass = 'searchOpen', otherContainerAttr = 'main'}) {
		super({root: root, element: element});
		this.openClass = openClass;
		this.otherContainerAttr = otherContainerAttr;
	}


	prepare() {
		this.searchId = this.dataAttr().get('for', '');
		if (this.searchId !== '') {
			this.searchLayer = this.components.queryComponent(this.root, this.dataSelector('id', this.searchId));
			if (this.searchLayer) {
				this.listeners.click = this.events.on(this.element, 'click', this.onClick.bind(this));
				this.otherContainer = this.root.querySelector(this.dataSelector(this.otherContainerAttr));
			} else {
				this.element.style.display = 'none';
			}
		}
	}


	onClick() {
		const isOpen = this.classList(document.body).contains(this.openClass);
		if (!isOpen) {
			// const currentOffset = getOffsetTop(this.otherContainer) * 0;
			const scrollPos = getScrollTop();
			// this.otherContainer.style.top = (currentOffset - scrollPos) + 'px';
			this.otherContainer.style.top = (-scrollPos) + 'px';
			this.prevScrollPos = scrollPos;
			window.scrollTo(0, 0);
		}

		this.classList(document.body).toggle(this.openClass);

		if (isOpen) {
			this.otherContainer.style.removeProperty('top');
			window.scrollTo(0, this.prevScrollPos);
		}
	}
}

export default SearchButton;
