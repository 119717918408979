import Cookies from 'js-cookie';
import PageComponent from '../../common/component/page-component';


class CookieConsent extends PageComponent {

	constructor({
		root,
		element,
		hideClass = 'hidden',
		acceptAttribute = 'accept',
		buttonAttribute = 'button',
		buttonsAttribute = 'buttons',
		cookieUrlAttribute = 'cookieUrl',
		cookieNameAttribute = 'cookieName',
		cookieAssetsAttribute = 'cookieAssets',
		cookieScriptAttribute = 'cookieScript',

	}) {
		super({root: root, element: element});
		this.hideClass = hideClass;
		this.buttonAttribute = buttonAttribute;
		this.acceptAttribute = acceptAttribute;
		this.buttonsAttribute = buttonsAttribute;
		this.cookieUrlAttribute = cookieUrlAttribute;
		this.cookieNameAttribute = cookieNameAttribute;
		this.cookieScriptAttribute = cookieScriptAttribute;
		this.cookieAssetsAttribute = cookieAssetsAttribute;
	}


	prepare() {
		this.cookieName = this.dataAttr(this.element).get(this.cookieNameAttribute);
		const buttonsNode = this.element.querySelector(this.dataSelector(this.buttonsAttribute));
		this.listeners.buttonsClick = this.events.on(buttonsNode, this.dataSelector(this.buttonAttribute), 'click', this.onClick.bind(this));
	}

	onClick(event, target) {
		const dataAttr = this.dataAttr(target);
		const userChoice = dataAttr.get(this.acceptAttribute) === true ? 1 : 0;
		Cookies.set(this.cookieName, userChoice, {expires: 180});

		if (userChoice) {
			this.insertTemplate();
		}
		this.closeNotification();
	}


	closeNotification() {
		this.classList(this.element).add(this.hideClass);
		this.onTransitionEnd(this.element).then(() => {
			this.element.parentNode.removeChild(this.element);
		});
	}

	insertTemplate() {
		const cookieAssetsTemplate = document.querySelector(this.dataSelector(this.cookieAssetsAttribute));
		if (cookieAssetsTemplate) {
			console.log(cookieAssetsTemplate, this.dataSelector(this.cookieAssetsAttribute));
			const clone = cookieAssetsTemplate.content.cloneNode(true);
			// const cookieScriptNode = clone.querySelector(this.dataSelector(this.cookieScriptAttribute));
			// cookieScriptNode.removeAttribute('type');
			cookieAssetsTemplate.parentNode.append(clone);

			cookieAssetsTemplate.parentNode.removeChild(cookieAssetsTemplate);
		}
	}
}


export default CookieConsent;
