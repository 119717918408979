import NextSlideLoader from './next-slide-loader';
import ZoomableImageSlide from './zoomable-image-slide';

class NextZoomableSlideLoader extends NextSlideLoader {

	loadNext(slide) {
		return super.loadNext(slide).then(() => {
			const nextSlide = this.getNextSlide(slide);
			if (nextSlide && nextSlide instanceof ZoomableImageSlide) {
				return nextSlide.loadLarge();
			}
			return Promise.resolve();
		});
	}
}

export default NextZoomableSlideLoader;
