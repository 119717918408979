import animationConfig from '../../common/animation/_config';
import appConfig from '../../common/app/_config';
import componentConfig from '../../common/component/_config';
import contextConfig from '../../common/context/_config';
import diConfig from '../../common/di/_config';
import domConfig from '../../common/dom/_config';
import eventsConfig from '../../common/events/_config';
import mediaConfig from '../../common/media/_config';
// import navigationConfig from '../../common/navigation/_config';
// import pageConfig from '../../common/page/_config';
import preloadConfig from '../../common/preload/_config';
// import routerConfig from '../../common/router/_config';
import slideshowConfig from '../../common/slideshow/_config';
import widgetConfig from '../../common/widget/_config';


const config = (di) => {
	animationConfig(di);
	appConfig(di);
	componentConfig(di);
	contextConfig(di);
	diConfig(di);
	domConfig(di);
	eventsConfig(di);
	mediaConfig(di);
	// navigationConfig(di);
	// pageConfig(di);
	preloadConfig(di);
	// routerConfig(di);
	slideshowConfig(di);
	widgetConfig(di);
};


export default config;
