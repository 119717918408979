import domMixin from '../dom/dom-mixin';


class SlideAnimator extends domMixin() {

	setup(slideshow) {}


    switch(currentSlide, newSlide, direction) {
		return Promise.resolve();
	}

	destroy() {}

}


export default SlideAnimator;
