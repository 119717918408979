import PageComponents from './page-components';
import PageComponent from './page-component';
import PageComponentFactory from './page-component-factory';
import componentsMixin from './components-mixin';

export default (di) => {
	di
		.setType({
			type: PageComponents, name: 'PageComponents', mixins: ['domMixin'], setters: {
				injectFactory: di.lazyGet('component/factory')
			}
		})
		.set('component/components', di.lazyNew('PageComponents'))

		.setType({type: PageComponent, name: 'PageComponent', mixins: ['domMixin', 'listenerMixin', 'asyncEventMixin', 'componentsMixin', 'contextsMixin']})

		.setType({type: PageComponentFactory, name: 'PageComponentFactory', parent: 'Factory'})
		.set('component/factory', di.lazyNew('PageComponentFactory'))

		.setMixin({
			mixin: componentsMixin, name: 'componentsMixin', setters: {
				injectComponents: di.lazyGet('component/components')
			}
		})
		;
};
